<!-- 运营报修确认-->
<template>
  <div class="order-confirm-wrapper flex">
    <div class="search-content searchBox">
      <search v-model="filter" @search="search" @reset="resetSearch"></search>
    </div>
    <div class="table-content inline-flex contentBox">
      <el-table :data="tableData" class="contentTable" size="medium" row-key="id" :tree-props="{children:'child',hasChildren:'hasChildren'}">
        <el-table-column prop="orderNo" label="工单号"></el-table-column>
        <el-table-column label="报修区域">
          <template slot-scope="{row}">
            {{row.repairArea.areaName}}
          </template>
        </el-table-column>
        <el-table-column label="报修人员">
          <template slot-scope="{row}">
            {{row.createBySysUserName && row.createBySysUserName.username}}
          </template>
        </el-table-column>
        <el-table-column label="联系电话">
          <template slot-scope="{row}">
            {{row.createBySysUserName && row.createBySysUserName.mobile}}
          </template>
        </el-table-column>
        <el-table-column label="故障类型">
          <template slot-scope="{row}">
            {{row.repairType.typeName}}
          </template>
        </el-table-column>
        <el-table-column label="维修费用">
          <template slot-scope="{row}">
            {{row.price?row.price/100:''}}
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="报修时间"></el-table-column>
        <el-table-column prop="statusName" label="状态">
          <template slot-scope="{row}">
             <span class="property-status" :class="[getStatus(row.status,'className')]" >
              {{getStatus(row.status,'statusName')}}</span>
          </template>
        </el-table-column>
        <el-table-column  label="操作">
          <template  slot-scope="{row}">
            <span class="mgr10 blue" v-if="row.status===11" @click="confirm(row.id)">确认</span>
            <span class="mgr10 blue" @click="showDetail(row.id)">查看</span>
          </template>
        </el-table-column>
      </el-table>
      <reconova-pagination @paginationChange="paginationChange" :page-no="pageIndex" :page-size="pageSize" :total="total"></reconova-pagination>
    </div>
    <div class="dialog-wrapper">
    </div>
  </div>
</template>

<script>
import ReconovaPagination from "@/components/reconova/reconovaPagination";
import pagination from "@/mixins/pagination";
import confirm from "../utils/confirm"
import Search from "../components/search";
export default {
  name: "property-repair-confirm-list",
  components: {
    Search,
    ReconovaPagination
  },
  mixins:[pagination],
  data(){
    return {
      filter:{
        orderNo:'',
        submitStartTime:'',
        submitEndTime:''
      },
      editData:{},
      tableData:[],
      confirmStatus:[12,13],
      statusMap:null,

    }
  },
  mounted(){
    this.statusMap=new Map([
      [11,['to-be-confirm','待确认']],
      [this.confirmStatus,['confirmed','已确认']],
    ])
    this.search()
  },
  methods:{
    // 查询
    search(pageSize=10,pageIndex=1){
      let requestParam = this.getParams(pageSize,pageIndex);
      this.$api.property.getPropertyConfirmList(requestParam).then(response=>{
        this.tableData = response.data.list;
        this.total = response.data.total;
        this.pageIndex = response.data.pageNum;
        this.pageSize = response.data.pageSize;
      })
    },

    /**
     * @param status  -- 状态
     * @param prop  -- className/statusName
     */
    getStatus(status,prop){
      if(this.confirmStatus.includes(status)){
        return prop==='className'?this.statusMap.get(this.confirmStatus)[0]:this.statusMap.get(this.confirmStatus)[1]
      }else{
        return prop==='className'?this.statusMap.get(status)[0]:this.statusMap.get(status)[1]
      }
    },

    showDetail(id){
      this.$router.push(`/checkModule/property-service/property-repair-confirm/detail/${id}`)
    },

    confirm(id){
      confirm.confirmPrompt(this,(reason)=> {
        this.$api.property.propertyConfirm({id,reason}).then(res => {
          this.$message.success('操作成功');
          this.search()
        })
      })
    }
  }
}
</script>

<style scoped>
.property-status.to-be-confirm{
  background: #188ffe;
}
.property-status.confirmed{
  background: #6fb504;
}
.range-input{
  width: 400px;
}
.el-table{
  flex: 1;
  overflow-y: auto;
}
</style>