<template>
  <div>
      <div class="search-item">
        <label>工单号</label>
        <el-input type="text" v-model="filter.orderNo" placeholder="请输入" size="medium" clearable></el-input>
      </div>
      <div class="search-item">
        <label>提交日期</label>
        <el-date-picker class="range-input"
                        :default-time="['00:00:00','23:59:59']"
                        size="medium"
                        :picker-options="pickerOptions"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        type="datetimerange"
                        is-range
                        v-model="time"
                        range-seperator="至"
                        start-placeholder="开始时间"
                        @change="timeChange"
                        end-placeholder="结束时间"></el-date-picker>
      </div>
      <div class="search-item button-group  pdl16">
        <el-button type="primary" class="btn-search" @click="search()" size="medium">查询</el-button>
        <el-button  class="btn-reset" @click="time=[];reset()" size="medium">重置</el-button>
      </div>
  </div>
</template>

<script>
export default {
  name: "search",
  props:['value'],
  computed:{
    filter:{
      get(){
        return this.value
      },
      set(val){
        this.$emit("input",val)
      }
    }
  },
  data(){
    return {
      time:[],
      //设置时间可选范围
      pickerOptions: {
        disabledDate(date){
          return date > new Date(new Date().setHours(23,59,59)).getTime()
        }
      },
    }
  },
  methods:{
    timeChange(value){
      this.filter.submitStartTime =  value?value[0]:'';
      this.filter.submitEndTime = value?value[1]:'';
    },
    search(){
      this.$emit('search')
    },
    reset(){
      this.$emit('reset')
    }
  }
}
</script>

<style scoped>
.el-input,.el-select {
  width: 220px;
}
</style>